import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Box,
  Paper,
  Button,
  Grow,
  useMediaQuery,
} from "@material-ui/core"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Textfield from "../components/TextField"
import Cards from "../components/Cards"
import LocalizedLink from "../components/LocalizedLink"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import { SEO } from "../components/SEO"

const blogTheme = createMuiTheme(zhutravelTheme, {
  overrides: {
    MuiCardContent: {
      root: {
        padding: zhutravelTheme.spacing(3),
        paddingLeft: 0,
        paddingRight: zhutravelTheme.spacing(4),
      },
    },
    MuiTypography: {
      h1: {},
      overline: {
        fontFamily: "MabryProLight",
      },
    },
  },
})

const BlogScreen = ({ pageContext, location, data }) => {
  const {
    seoTitle,
    seoDescription,
  } = data.allContentfulBlogIndexSeo.edges[0].node
  const blogs = data.allContentfulBlogs.edges
  const [displayCount, setDisplayCount] = useState(7)
  const mdUp = useMediaQuery(zhutravelTheme.breakpoints.up("md"))

  return (
    <Layout selected={"blog"} location={location} pageContext={pageContext}>
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <Container>
        <ThemeProvider theme={blogTheme}>
          <Box mt={mdUp ? 11 : 7} mb={mdUp ? 13 : 6}>
            <Typography variant="h1">Blog</Typography>
          </Box>
          <Grid container spacing={3}>
            {blogs.slice(0, displayCount).map(({ node }, index) => (
              <Grow
                key={node.slug}
                in={index < displayCount}
                mountOnEnter
                unmountOnExit
              >
                <Grid item xs={12} md={index <= 0 ? 12 : 4}>
                  <Card
                    elevation={0}
                    square
                    style={index <= 0 && mdUp ? { marginBottom: "80px" } : null}
                  >
                    <CardActionArea
                      component={LocalizedLink}
                      to={`/blog/${node.slug}`}
                    >
                      <CardMedia>
                        <Img
                          fluid={node.blogMainImage.fluid}
                          style={{
                            height: index <= 0 && mdUp ? "360px" : "300px",
                          }}
                        />
                        {index <= 0 && mdUp ? (
                          <Box position="absolute" bottom={0} width={1}>
                            <Grid container spacing={3}>
                              <Grid item md={4}>
                                <CardContent
                                  component={Paper}
                                  square
                                  elevation={0}
                                >
                                  <Typography variant="overline">
                                    {node.blogPublishDate}
                                  </Typography>
                                  <Typography variant="h6">
                                    {node.blogTitle}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {node.blogSubTitle.blogSubTitle}
                                  </Typography>
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : null}
                      </CardMedia>

                      {!mdUp || index > 0 ? (
                        <CardContent>
                          <Typography variant="overline">
                            {node.blogPublishDate}
                          </Typography>
                          <Typography variant="h6">{node.blogTitle}</Typography>
                          <Typography variant="subtitle1">
                            {node.blogSubTitle.blogSubTitle}
                          </Typography>
                        </CardContent>
                      ) : null}
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grow>
            ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            mt={mdUp ? 5 : 3}
            mb={mdUp ? 15 : 10}
          >
            <Button
              startIcon={<AddIcon />}
              onClick={() => setDisplayCount(displayCount + 3)}
              disabled={displayCount >= blogs.length}
            >
              Load more
            </Button>
          </Box>
        </ThemeProvider>
      </Container>
      <Divider />
      <Cards
        tourPageMainData={data.allContentfulTourPageModel?.edges?.[0]?.node}
        dmcPageMainData={data.allContentfulDmcPageModel?.edges?.[0]?.node}
        investPageMainData={data.allContentfulInvestPageModel?.edges?.[0]?.node}
        title="Our services"
        isBlog
      />
      <Divider />
      <Textfield
        backgroundColor={"black"}
        customImage={data.file.childImageSharp}
        locale={pageContext.locale}
      />
      <Divider />
      <Footer locale={pageContext.locale} />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String!) {
    allContentfulBlogs(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          blogTitle
          blogSubTitle {
            blogSubTitle
          }
          slug
          blogPublishDate(formatString: "DD MMM YYYY")
          blogMainImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    allContentfulBlogIndexSeo(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          seoTitle
          seoDescription
        }
      }
    }

    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          topSectionPageTitle
          topSectionDescription {
            topSectionDescription
          }
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageSubTitle
        }
      }
    }
    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageDescription {
            pageDescription
          }
        }
      }
    }
    file(name: { eq: "FundoGiris" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default BlogScreen
